const starOfMonthData = [
    {
        id: 'sep',
        title: '九月之星',
        etitle: 'Stars of September',
        thumbnail:
            'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f1.jpg',
        students: [
            {
                from: 1,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f1.jpg',
                studentName: ['1A - 袁麗鐶', '1B - 黃嘉婕', '1C-LAYLAY STEFHANNY'],
            },
            {
                from: 2,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f2.jpg',
                studentName: [
                    '2A - 陳思思',
                    '2B - 褚淑鑫',
                    '2C - IFZA ISHTIAQ'
                ],
            },
            {
                from: 3,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f3.jpg',
                studentName: [
                    '3A - 薛依瑞',
                    '3B - ABDURREHMAN-FAROQ',
                ],
            },
            {
                from: 4,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f4.jpg',
                studentName: ['4A - 甄柏豪', '4B - 蔡卓宏', '4C - PANT GANDY SUMINGUIT'],
            },
            {
                from: 5,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f5.jpg',
                studentName: [
                    '5A - 鄭詠姍',
                    '5B - 黃依藍',
                    '5C - RANA RIVA'
                ],
            },
            {
                from: 6,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-sep/f6.jpg',
                studentName: ['6A - 徐芷翹', '6B - 鄭艾琳', '6C - RANJEET-SINGH'],
            },
        ],
    },
    {
        id: 'oct',
        title: '十月之星',
        etitle: 'Stars of October',
        thumbnail:
            'https://res.cloudinary.com/waynechanchan/image/upload/v1736231347/starOfTheMonth/oct/nbs5alhisivnh82cf1nj.jpg',
        // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
        students: [
            {
                from: 1,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231347/starOfTheMonth/oct/nbs5alhisivnh82cf1nj.jpg',
                studentName: ['1A - 蔡嘉熙', '1B - 李煒霖', '1C - LIMBU LALGIE'],
            },
            {
                from: 2,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231349/starOfTheMonth/oct/kdv2lb4lwwghim4khzhf.jpg',
                studentName: [
                    '2A - 劉子成',
                    '2B - 黃星暉',
                    '2B - IFZA ISHTIAQ'
                ],
            },
            {
                from: 3,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231350/starOfTheMonth/oct/iplafc94g39qvfzjsqzw.jpg',
                studentName: [
                    '3A - 蔡綺寧',
                    '3B - ABDURREHMAN-FAROQ',
                ],
            },  
            {
                from: 4,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231350/starOfTheMonth/oct/gi62hohrkwrypd9n7ay4.jpg',
                studentName: ['4A - 黃嘉麗', '4B - 鄭香怡', '4C-PANT GANDY SUMINGUIT'],
            },
            {
                from: 5,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231351/starOfTheMonth/oct/gf4ejexccnbjkcxahqge.jpg',
                studentName: [
                    '5A - 馬安昊',
                    '5B - 吳梓熙',
                    '5C - RANA RIVA'
                ],
            },
            {
                from: 6,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1736231349/starOfTheMonth/oct/erbhaxbollnqti2hqtqj.jpg',
                studentName: [
                    '6A - 陳錦佺',
                    '6B - 文天賜',
                    '6C - RANJEET-SINGH'
                ],
            },
        ],
    },
    {
        id: 'nov',
        title: '十一月之星',
        etitle: 'Stars of Nov',
        thumbnail:
            'https://res.cloudinary.com/waynechanchan/image/upload/v1741911246/starOfTheMonth/nov/F1_rx1rtj.jpg',
        // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
        students: [
            {
                from: 1,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1741911246/starOfTheMonth/nov/F1_rx1rtj.jpg',
                studentName: ['1A - 曹安誼', '1B - 陳靖詩', '1C - ABBAS HINA'],
            },
            {
                from: 2,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1741911239/starOfTheMonth/nov/F2_djuair.jpg',
                studentName: [
                    '2A - 蘇米樂',
                    '2B - 褚金鎮',
                    '2B - LIMBU LALGIE',
                ],
            },
            {
                from: 3,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1741911243/starOfTheMonth/nov/F3_kmmtuz.jpg',
                studentName: [
                    '3A - 黃雍',
                    '3B - PANGANG-AT LEGEND FAITH MILLER',
                ],
            },
            {
                from: 4,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1741911246/starOfTheMonth/nov/F4_xhe30q.jpg',
                studentName: ['4A - 郭志聰', '4B - 鍾可翔', '4C-SHAH SYED ABBAS'],
            },
            {
                from: 5,
                url: 'https://res.cloudinary.com/waynechanchan/image/upload/v1741911243/starOfTheMonth/nov/F5_dwyqji.jpg',
                studentName: [
                    '5A - 李仲言',
                    '5B - 章芷蕙',
                    '5C - LINDSEY JOANNA SHAE DONGETO',
                ],
            },
            // {
            //     from: 6,
            //     url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f6.jpg',
            //     studentName: [
            //         '6A - 李德勤',
            //         '6B - RAI NORAH'
            //     ],
            // },
        ],
    }

    // {
    //     id: 'decjan',
    //     title: '十二月至一月之星',
    //     etitle: 'Stars of Dec to Jan',
    //     thumbnail:
    //         'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f1-web.jpg',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f1-web.jpg',
    //             studentName: ['1A - 陳思思', '1B - 吳晓藍', '1C - LIMBU LALGIE'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f2-web.jpg',
    //             studentName: [
    //                 '2A - 黃芷萱',
    //                 '2B - SIAKI MAIKELI LAGILAGI',
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f3-web.jpg',
    //             studentName: [
    //                 '3A - 陳冠峯',
    //                 '3B - 鄧梓標',
    //                 '3C - PUN RAZ',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f4-web.jpg',
    //             studentName: ['4A - 徐健鋒', '4B - 王祖迪', '4C-GURUNG ASHESH'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f5-web.jpg',
    //             studentName: [
    //                 '5A - 薛欣純',
    //                 '5B - 陳嘉琪',
    //                 '5C - MALIK HINA',
    //             ],
    //         },
    //         {
    //             from: 6,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f6-web.jpg',
    //             studentName: [
    //                 '6A - 施鴻毅',
    //                 '6B - GURUNG ALBIN'
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 'feb_mar',
    //     title: '二月至三月之星',
    //     etitle: 'Stars of Feb to Mar',
    //     thumbnail:
    //         'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F1.JPG',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F1.JPG',
    //             studentName: ['1A - 蔡文濤', '1B - 黃梓韵', '1C - MUHAMMAD AHTESHAM ALI'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F2.JPG',
    //             studentName: [
    //                 '2A - 高文銳',
    //                 '2B - KAMRAN RAMESHA'
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F3.JPG',
    //             studentName: [
    //                 '3A - 吳偉樂',
    //                 '3B - 鍾可翔',
    //                 '3C - GURUNG CHAHANA',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F4.JPG',
    //             studentName: ['4A - 王鎧儀', '4B - 鄧鈞圯', '4C-NAWAZ YUSSEF'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-feb-mar/F5.JPG',
    //             studentName: [
    //                 '5A - 楊可盈',
    //                 '5B - 文天賜',
    //                 '5C - CARMONA JAYDEN CURATA'
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 'apr',
    //     title: '四月之星',
    //     etitle: 'Stars of April',
    //     thumbnail:
    //         'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F1.jpg',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F1.jpg',
    //             studentName: ['1A - 龐龔如', '1B - 黃星暉', '1C - RAFIQ ANITA'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F2.jpg',
    //             studentName: [
    //                 '2A - 曾濼軒',
    //                 '2B - LIMBU SALOMA'
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F3.jpg',
    //             studentName: [
    //                 '3A - 王若溪',
    //                 '3B - 朱泰荣',
    //                 '3C - DELA CRUZ, RHIANNE MAE PLATA',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F4.jpg',
    //             studentName: ['4A - 何兆星', '4B - 黃雅琴', '4C-NAWAZ YUSSEF'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2024-apr/F5.jpg',
    //             studentName: [
    //                 '5A - 葉家謙',
    //                 '5B - 王坤杰',
    //                 '5C - CRUZ HANZ REMOH DIZON'
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 'mar',
    //     title: '三月至四月之星',
    //     etitle: 'Stars of Mar to Apr',
    //     thumbnail:
    //         'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F1.JPG',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F1.JPG',
    //             studentName: ['1A - 黃子謙', '1B - PASCUAL KINFER ZION GENESIS SELECCION'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F2.JPG',
    //             studentName: [
    //                 '2A - 鄭湘悠',
    //                 '2B - 楊雅茜',
    //                 '2C - JAVED SIMRAN',
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F3.JPG',
    //             studentName: [
    //                 '3A - 羅海欣',
    //                 '3B - LIMBU ANUSH',
    //                 '3C - BUNSRI KALIN',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F4.JPG',
    //             studentName: ['4A - 張植嘉', '4B - 郭金慧', '4C - MALIK HINA'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F5.JPG',
    //             studentName: [
    //                 '5A - 文家豪',
    //                 '5B - LINDSEY JERIMIAH D.',
    //             ],
    //         },
    //     ],
    // }


    
];

export default starOfMonthData;
